<template>
  <div class="rightsList">
    <div class="main">
      <van-form class="form-wrap">
        <div class="ptr">
          <van-field clearable @blur="telVerify" label="手机号" :maxlength="11"  label-width="70px" placeholder="请输入手机号" v-model="telText" type="tel" :rules="[{required:true,message:' ',validator:telVerify}]"></van-field>
        </div>
        <div class="ptr">
          <div class="flexbt">
            <van-field clearable class="msgCode" @blur="smsVerify" label-width="70px" :maxlength="6"
                        :rules="[{required:true,message:'  ',validator:smsVerify}]" label="   " placeholder="请输入验证码" v-model="smsText"/>
            <van-button class="msgBtn" @click="getSms()" :disabled="smsDisabled" :class="{active: isActive}" >{{smsTime < 0 ? (smsFlag? '重新发送':  '获取验证码'): "重新发送 " + smsTime + " s"}}</van-button>
          </div>
        </div>
        <p class="errorMsg" v-if="phoneError">请输入正确的手机号</p>
        <p class="errorMsg" v-if="!phoneError && smsError">请输入正确的验证码</p>
      </van-form>
      <div class="footer">
        <van-button class="loginBtn" slot="button" size="small" @click="nextPage">领取权益</van-button>
      </div>
    </div>
      
    <div class="rights-wrap">
      <img :src="rightsbg" alt="">
      <div class="right-content">
        <div class="flex title-wrap">
<!--
          <img :src="jdlogo">
-->
          <div>{{rightsInfo.name}}</div>
        </div>
        <div class="code">兑换码：{{rightsInfo.code}}</div>
        <div class="date">有效期至{{rightsInfo.endDate}}</div>
      </div>
    </div>
   
    <van-overlay :show="show" @click="show = false">
      <div class="overlay-wrapper" @click.stop>
        <div class="block">
          <div class="title">恭喜您！</div>
          <div class="content">成功领取{{rightsInfo.name}}！立即去公众号使用~</div>
          <div class="flex overlay-button">
            <van-button  @click="show = false">知道了</van-button>
            <van-button class="sure" @click="gotoUse">去使用</van-button>
          </div>
        </div>

      </div>
    </van-overlay>

  </div>
</template>

<script>
import common from '@/utils/common'
import { getSmsMessagebenefit, checkSmsMessage, getBenefitInfo, receiveBenefit, wechatGZH } from '@/utils/api'
import MobileDetect from 'mobile-detect'
import {uwStatisticAction} from "../../utils/util";
export default {
  name: "rightsList",
  data() {
    return {
      telText: "",
      smsText: "",
      smsFlag: false,
      smsCode: false,
      smsTime: -1,
      timer: null,
      smsDisabled: false,
      isActive: false,
      isDisabled: false,
      pointCount:0,
      phoneError: false,
      smsError: false,
      md: new MobileDetect(window.navigator.userAgent),
      rightsbg: require('@/assets/img/rights/rightsbg.png'),
      jdlogo: require('@/assets/img/rights/jdlogo.png'),
      show: false,
      benefitId: '',
      rightsInfo: {}
    }
  },
  methods: {
    toreceiveBenefit(){
      receiveBenefit({
        phoneNo: this.telText.replace(/\s+/g,""),
        captch: this.smsText,
        benefitId: this.benefitId,
        msgType: 'code.h5.benefit',
      }).then(res=>{
        if(res.code === 200){
          if(res.data.businessCode && res.data.businessCode === 200){
            this.show = true
          }
           if(res.data.businessCode && res.data.businessCode === 20008){
            this.gotoUse()
          }
        }
      })
    },
    togetBenefitInfo() {
      getBenefitInfo({
        benefitId:this.$route.query.benefitId
      }).then(res=>{
        if(res.code === 200){
          if(res.data.businessCode && res.data.businessCode === 200){
            this.rightsInfo = res.data.data
            if(res.data.data.phoneNo){
              this.$router.push({name: 'rightsLogin'})
            }
          }
        }
      })
    },
    gotoUse() {
      window.location.href = wechatGZH
    },
    clearPhone() {
      this.telText = ''
      this.phoneError = false
    },
    clearSms() {
      this.smsText = ''
      this.smsError = false
    },
    // 手机号失去焦点
    telVerify(val) {
      common.kickBack();
      this.phoneError = !common.phoneVerify(this.telText.replace(/\s+/g,""))
      return  !this.phoneError
    },
    // 短信验证码失去焦点
    smsVerify() {
      this.smsError = !common.numberVerify(this.smsText)
     return !this.smsError
    },
    // 短信倒计时
    smsCountDown() {
      this.smsTime = 60;
      this.isActive = true;
      this.smsDisabled = true;
      this.timer = setInterval(() => {
        this.smsTime--;
        if (this.smsTime < 0) {
          this.isActive = false;
          this.smsDisabled = false;
          clearInterval(this.timer);
        } else {
          this.isActive = true;
          this.smsDisabled = true;
        }
      }, 1000);
    },
    // 获取短信验证码
    getSms() {
      console.log("hhhh")
      if (!this.telText.replace(/\s+/g,"")) {
        this.phoneError = true
        return
      }
      if (!common.phoneVerify(this.telText.replace(/\s+/g,""))) {
        this.phoneError = true
        return
      }
      getSmsMessagebenefit({
        phoneNo: this.telText.replace(/\s+/g,""),
        msgType: 'code.h5.benefit'
      }).then(res=>{
        if(res.code === 200){
          // let data = JSON.parse(common.decrypt(res.data, res.timestamp))
          if(res.data.businessCode && res.data.businessCode === 200){
            this.phoneError = false
            this.smsFlag = true
            this.smsCode = true
            this.smsCountDown()
            this.$toast(res.data.businessMessage)
          }
        }
      })
    },
    // 校验短信验证码
    checkSms() {
      if (!this.smsCode) {
        return
      }
      checkSmsMessage({
        phoneNo: this.telText.replace(/\s+/g,""),
        msgType: 'code.h5.login',
        captch: this.smsText,
        loginAgreementInfo: "{'ag_001':'v1.0.1','ag_002':'v1.1.1'}",
        phoneModel: JSON.stringify(this.md.phone())
      }).then(res=>{
        if(res.code === 200){
          if(res.data.businessCode && res.data.businessCode === 200){
            this.toreceiveBenefit()
          }
        }
      })
    },
    // 点击下一步
    nextPage() {
      this.pointCount++;
      if (!this.telText.replace(/\s+/g,"")) {
        this.phoneError = true
        return
      }
      if (!common.phoneVerify(this.telText.replace(/\s+/g,""))) {
        this.phoneError = true
        return
      }
      if (!this.smsText) {
        this.smsError = true
        return
      }
      if (!this.smsFlag) {
        this.$toast('请点击发送短信验证码')
        return
      }
      if (!common.numberVerify(this.smsText)) {
        this.smsError = true
        return
      }
      this.toreceiveBenefit()
      // this.checkSms()
    },
  },
  mounted() {
    uwStatisticAction('/rightsList','权益平台')
    if(this.$route.query.benefitId){
      this.benefitId = this.$route.query.benefitId
      this.togetBenefitInfo()
    }
  },
}
</script>

<style scoped lang="less">
.rightsList {
  height: 100%;
  font-size: 0.3rem;
  background: #F5F5F5;
  .rights-wrap{
    padding: 0.3rem;
    position: relative;
    img{
      width:6.9rem;
      height: 2.24rem;
    }
    .right-content{
      position: absolute;
      left: 0.3rem;
      top: 0.3rem;
      padding: 0.3rem;
      width: 6.9rem;
      .title-wrap{
        align-content: center;
        div{
          font-size: 0.4rem;
          color: #3A6991;
          margin-left:0.1rem;
        }
        img{
          width: 0.4rem;
          height: 0.4rem;
          margin-top: 0.1rem;
        }
      }
      .code{
        font-size: 0.24rem;
        color: #85A4BF;
        margin-top: 0.1rem;
      }
      .date{
        font-size: 0.22rem;
        color: #3A6991;
        margin-top: 0.25rem;
      }
    }
  }
  .overlay-wrapper{
    height: 100%;
    .block{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6rem;
      background-color: #fff;
      text-align: center;
      border-radius: 8px;
        .title{
          font-weight: bold;
          font-size: 0.34rem;
          margin: 0.5rem 0 0.3rem;
        }
        .content{
          padding: 0 0.48rem 0.8rem;
        }
    }
    .overlay-button{
      .van-button{
        flex:1;
      }
    }
  }
  .ptr{
    border-bottom: 1px solid #eee;
  }
  .flexbt{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    h3{
      font-size:27px;
      font-family:PingFangSC-Medium,PingFang SC;
      font-weight:500;
      text-align: center;
      margin: 8px 0  38px 0;
    }
    .msgCode{
      width: calc(100% - 140px);
    }
    .form-wrap{
      position: relative;
    }
    .errorMsg{
      color: #f4664a;
      font-size: 12px;
      position: absolute;
      bottom: -0.7rem;
      left: 0.3rem;
    }
    .msgBtn{
      border: none;
      // border-left: 1px solid #eee;
      font-size:17px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color: #3F7C53;
      width: 180px;
      height: 0.95rem;
      text-align: right;
      // line-height: 40px;
    }
    .inp {
      position: relative;
      .phone, .sms{
        margin: 0.1rem 0.2rem;
      }
      .sms{
        margin-top: 0.4rem;
      }
      input {
        width: 100%;
        height: 1rem;
        font-size: 0.3rem;
        background-color: #fff;
        padding-left: 0.2rem;
        border-bottom: 1px solid #dfdfdf;
        border-top: 1px solid #dfdfdf;
        position: relative;
      }
      .phone-cross{
        position: absolute;
        right: 0.3rem;
        top: 58%;
      }
      .sms-cross{
        position: absolute;
        right: 2.3rem;
        top: 58%;
      }
      button {
        width: 2.2rem;
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.3rem;
        color: #3F7C53;
        background: #fff;
        text-align: center;
        position: absolute;
        right: 0;
        top: 48%;
      }
    }
    .error-msg{
      color: #ee0a24;
      padding-left: 86px;
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      position: absolute;
      left: 0;
      bottom: 0px;
    }
  }
  .footer{
    text-align: center;
    padding-top: 0.8rem;
    padding-bottom: 0.52rem;
    background: #fff;
    .loginBtn{
      background: #3F7C53;
      height: 0.8rem;
      line-height: 0.8rem;
      border-radius: 4px;
      width: 3.4rem;
      margin: 0 auto;
      font-size:0.34rem;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color: #ffffff;
    }
  }
}
</style>
<style lang="less">
.rightsList .van-cell{
  padding: 12px;
}
.rightsList .van-cell:not(:last-child)::after{
  opacity: 0;
}
.rightsList .van-field__control ,.rightsList .van-field__label,.rightsList  .van-field__value{
  font-size: 17px;
  color: #000;
}
.rightsList .van-field__error-message{
  margin-top: -3px;
}
.rightsList .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
  color: #999;
}
.rightsList .block .van-button{
  &:nth-child(1){
    border-bottom-left-radius: 8px;
    border: 1px solid #eee;
    color: #000;
  }
  &:nth-child(2){
    border-left: 0;
    border-bottom-right-radius: 8px;
    color: #576B95;
  }
  
  
}
.rightsList .block .sure{
  color:#3F7C53 !important; ;
}
</style>